<template>
  <a-drawer width="55%" :label-col="4" :wrapper-col="14"  :maskClosable="(formType == 2 || formType == 3) ? true :false" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入备注" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="用户id关联{zb_user.id}" prop="userId" >-->
<!--        <a-input v-model="form.userId" placeholder="请输入用户id关联{zb_user.id}" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="Brand Product" prop="skuId" v-if="isShowSku">
        <a-select
          placeholder="-Brand Product-"
          style="width: 100%"
          showSearch
          v-model="form.skuId"
          optionFilterProp="title"
        >
          <a-select-option v-for="item in productListSku" :key="item.id" :value="item.id" :title="item.name + '('+item.productModel+')'">
            {{ item.name + '('+item.productModel+')' }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Flavor Name:" prop="name" >
        <a-input v-model="form.name" placeholder="" :maxLength="100" />
        <div style="display: flex;justify-content: flex-end">{{ ((form.name && form.name.length) || 0) }}/100</div>
      </a-form-model-item>
      <a-form-model-item label="Ingredients:" required>
        <a-button type="primary" @click.stop="addIngredients(1)" v-if="formType != 3">+Add Ingredients</a-button>
        <a-table :columns="ingredientsColumns" :data-source="ingredientsListData" bordered>

          <a-select
            placeholder="-Ingredients-"
            style="width: 100%"
            showSearch
            v-model="record.publicId"
            optionFilterProp="title"
            slot="name" slot-scope="text, record"
            @change="changeIngredientsType($event,record)"
          >
            <a-select-option v-for="item in ingredientsType" :key="item.id" :value="item.id" :title="item.name">
              {{ item.name }}
            </a-select-option>
          </a-select>

          <div slot="percentage"  slot-scope="text, record">
            <a-input-number v-model="record.flavorValue"  :min="0" :max="100"></a-input-number>
            %
          </div>
          <span slot="operation" slot-scope="text, record">
            <a @click="handleDelete(record,1)" v-if="formType != 3">
                          <a-icon type="delete" />Delete
                        </a>
                        </span>
                      </a-table>
                    </a-form-model-item>

                    <a-form-model-item label="Terpenes:" required>
                      <a-button type="primary"  @click.stop="addIngredients(2)" v-if="formType != 3">+Add Terpenes</a-button>
                      <a-table :columns="ingredientsColumns" :data-source="terpenesListData" bordered>
                        <a-select
                          placeholder="-Terpenes-"
                          style="width: 100%"
                          showSearch
                          v-model="record.publicId"
                          slot="name" slot-scope="text, record"
                          optionFilterProp="title"
                          @change="changeTerpenes($event,record)"
                        >
                          <a-select-option v-for="item in terpenesType" :key="item.id" :value="item.id" :title="item.name">
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                        <div slot="percentage"  slot-scope="text, record">
                          <a-input-number v-model="record.flavorValue"  :min="0" :max="100"></a-input-number>
                          %
                        </div>
                        <span slot="operation" slot-scope="text, record">
                        <a @click="handleDelete(record,2)" v-if="formType != 3">
                          <a-icon type="delete" />Delete
                        </a>
                        </span>
                      </a-table>
                    </a-form-model-item>
                    <a-form-model-item label="Usage Effects Tags:" prop="tags" >
                      <a-checkbox-group v-model="form.tags">
                        <a-checkbox :value="item.id" v-for="(item,index) in usageEffectsTags">
                          {{ item.name }}
                        </a-checkbox>
                      </a-checkbox-group>
                    </a-form-model-item>

                    <a-form-model-item label="Flavor Picture:" prop="url" >
<!--                      <a-input v-model="form.url" placeholder="" />-->
                      <a-upload
                        name="file"
                        v-if="formType != 3"
                        :multiple="true"
                        :show-upload-list="false"
                        :action="tupianshangchuanDizhi"
                        :headers="headers"
                        @change="handleChange($event)"
                      >
                        <a-button type="primary" style="margin-right: 10px;">Upload Picture</a-button>Recommended size of picture:240 pixels * 240 pixels, supporting JPG and PNG formats
                      </a-upload>

                      <div style="display:flex;flex-wrap: wrap;margin-top: 10px;" v-if="form.url">
                        <div style="display: flex;align-items: center; justify-content: center">
                          <img :src="form.url"  style="width: 200px;height: 200px;" />
                        </div>
                      </div>
                    </a-form-model-item>
                    <a-form-model-item label="Status" prop="status" >
              <!--        <a-input v-model="form.url" placeholder="请输入图片" />-->
        <a-radio-group v-model="form.status">
          <a-radio :value="1">
            Enable
          </a-radio>
          <a-radio :value="0">
            Disable
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <div class="bottom-control" v-if="formType != 3">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            Save
          </a-button>
          <a-button type="dashed" @click="cancel">
            Cancel
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getSkuFlavor, addSkuFlavor, updateSkuFlavor, delSkuFlavor} from '@/api/cigarette/skuFlavor'
import {listConfig} from "@/api/cigarette/config";
import {listSku} from "@/api/cigarette/sku";
import {delFlavorOption} from "@/api/cigarette/flavorOption";
import {tupianshangchuanDizhi} from '@/utils/request';
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      tupianshangchuanDizhi:tupianshangchuanDizhi,
      ingredientsListData: [],
      terpenesListData: [],
      ingredientsColumns: [{
        title: 'Name',
        scopedSlots: { customRender: 'name' },
      },{
        title: 'Percentage',
        scopedSlots: { customRender: 'percentage' },
      },{
        title: 'Operation',
        scopedSlots: { customRender: 'operation' },
      }],
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        userId: null,

        skuId: null,

        name: null,

        url: null,

        tags: null,

        status: 1

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        skuId: [
          { required: true, message: 'Brand Product cannot be empty' }
        ],
        name: [
          { required: true, message: 'Flavor Name cannot be empty', trigger: 'blur' }
        ],
        url:[
          { required: true, message: 'Flavor Picture cannot be empty' }
        ]
      },
      usageEffectsTags: [],
      productListSku: [],
      ingredientsType: [],
      terpenesType: [],
      headers:{},
      isShowSku:false
    }
  },
  filters: {
  },
  created () {
    this.headers = {
      "Authorization" : "Bearer " + this.$store.getters.token
    }
    this.getSkuFlaveConfigList();
    this.getSkuListData();
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    handleChange(e,item) {
      console.log(e)
      console.log(item);
      this.form.url = e.file.response.data
      // e.file.response.data
    },
    changeIngredientsType(e,record) {
      console.log(e);
    let newDate =this.ingredientsType.filter(i => i.id === e);
    this.$set(record,'publicName',newDate[0].name)
    },
    changeTerpenes(e,record) {
      let newDate =this.terpenesType.filter(i => i.id === e);
      this.$set(record,'publicName',newDate[0].name)
    },
    getSkuListData() {
      listSku({
        status:1
      }).then(res => {
        this.productListSku = res.data;
      })
    },
    handleDelete (row,num) {
      if(row.id > 0) {
        delFlavorOption([{
          id:row.id
        }]).then(res => {
          if(num == 1) {
            let index = this.ingredientsListData.findIndex(i => i.id === row.id);
            if(index !== -1) {
              this.ingredientsListData.splice(index,1)
            }
          } else {
            let index = this.terpenesListData.findIndex(i => i.id === row.id);
            if(index !== -1) {
              this.terpenesListData.splice(index,1)
            }
          }
        })
      } else {
       if(num == 1) {
         let index = this.ingredientsListData.findIndex(i => i.id === row.id);
         if(index !== -1) {
           this.ingredientsListData.splice(index,1)
         }
       } else {
         let index = this.terpenesListData.findIndex(i => i.id === row.id);
         if(index !== -1) {
           this.terpenesListData.splice(index,1)
         }
       }
      }
    },
    addIngredients(num) {
      if(num === 1) {
        if(this.ingredientsListData.length) {
          if(!this.ingredientsListData[this.ingredientsListData.length - 1].publicName || !this.ingredientsListData[this.ingredientsListData.length - 1].flavorValue) {
            this.$message.error("Add the data after filling it out");
            return;
          }
        }
        this.ingredientsListData.push({
          publicName:"",
          type:3,
          flavorValue:"",
          publicId:"",
          id:-(this.ingredientsListData.length)
        })

      } else {
        if(this.terpenesListData.length) {
          if(!this.terpenesListData[this.terpenesListData.length - 1].publicName || !this.terpenesListData[this.terpenesListData.length - 1].flavorValue) {
            this.$message.error("Add the data after filling it out");
            return;
          }
        }
          this.terpenesListData.push({
            publicName:"",
            type:4,
            flavorValue:"",
            publicId:"",
            id:-(this.ingredientsListData.length)
          })
      }
    },
    getSkuFlaveConfigList() {
      listConfig({
        type: 5,
        status: 1
      }).then(res => {
        if (res.success) {
          this.usageEffectsTags = res.data;
        }
      })

      listConfig({
        type: 3,
        status: 1
      }).then(res => {
        if (res.success) {
          this.ingredientsType = res.data;
        }
      })

      listConfig({
        type: 4,
        status: 1
      }).then(res => {
        if (res.success) {
          this.terpenesType = res.data;
        }
      })
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        remark: null,
        skuId: null,
        name: null,
        url: null,
        tags: null,
        status: 1
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.form.skuId  = row;
      if(this.form.skuId) {

        this.isShowSku = false;
      } else {
        this.isShowSku = true;
      }
      this.formType = 1
      this.open = true
      this.formTitle = 'Create'
    },
    handleShow(row, ids) {
      this.isShowSku = false;
      this.reset()
      this.formType = 3
      const id = row ? row.id : ids
      this.ingredientsListData= [];
      this.terpenesListData= [];
      getSkuFlavor({"id":id}).then(response => {
        this.form = response.data;
        this.ingredientsListData =  response.data.skuFlavorIngredientsOptions;
        this.terpenesListData = response.data.skuFlavorTerpenesOptions;
        this.form.tags = this.form.tags ? this.form.tags.split(',') : []
        this.open = true
        this.formTitle = 'Modify'
      })
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.isShowSku = false;
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      this.ingredientsListData= [];
        this.terpenesListData= [];
      getSkuFlavor({"id":id}).then(response => {
        this.form = response.data;
        this.ingredientsListData =  response.data.skuFlavorIngredientsOptions;
        this.terpenesListData = response.data.skuFlavorTerpenesOptions;
        this.form.tags = this.form.tags ? this.form.tags.split(',') : []
        this.open = true
        this.formTitle = 'Modify'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      if(this.ingredientsListData.length === 0 && this.terpenesListData.length === 0) {
        this.$message.info("Ingredients Or Terpenes cannot be empty")
        return;
      }
      let ingredientsData = [];
      this.ingredientsListData.forEach(i => {
        if(i.id < 0) {
          if(i.publicName && i.publicId) {
            ingredientsData.push({
              publicName:i.publicName,
              type:i.type,
              flavorValue:i.flavorValue,
              publicId:i.publicId
            })
          }

        } else {
          ingredientsData.push(i)
        }
      })
      this.form.ingredientsList = ingredientsData;
      // if(this.terpenesListData.length === 0) {
      //   this.$message.info("Terpenes cannot be empty")
      //   return;
      // }
      let terpenesListData = [];
      this.terpenesListData.forEach(i => {
        if(i.id < 0) {
          console.log('进来了');
          console.log(i);
          if(i.publicName && i.publicId) {
            terpenesListData.push({
              publicName:i.publicName,
              type:i.type,
              flavorValue:i.flavorValue,
              publicId:i.publicId,
            })
          }

        } else {
          terpenesListData.push(i)
        }
      })
      this.form.terpenesList = terpenesListData;

      let totalNum = 0;
      let sum = this.form.ingredientsList.reduce((accumulator, current) => accumulator + current.flavorValue, 0);
      totalNum = this.form.terpenesList.reduce((accumulator, current) => accumulator + current.flavorValue, sum);
      if(totalNum != 100) {
        this.$message.error('The composition of Terpenes and Ingredients should be 100%');
        console.log('不满足' + totalNum);
        return;
      }

      // console.log('满足100%')
      // console.log(totalNum)
      // return;
      this.form.tags = this.form.tags.length > 0 ? this.form.tags.join(',') : null;
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateSkuFlavor(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              this.terpenesListData = [];
              this.ingredientsListData = [];
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addSkuFlavor(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              this.terpenesListData = [];
              this.ingredientsListData = [];
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
